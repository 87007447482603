import PropTypes from "prop-types";
import * as Yup from "yup";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// form
import { Form, FormikProvider, useFormik } from "formik";
import { Persist } from "formik-persist";
// @mui
import { LoadingButton } from "@mui/lab";
import { Card, Stack } from "@mui/material";
// routes
import useAuth from "../../../hooks/useAuth";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { SALE_STATUS } from "../../../utils/apis";
//
import InvoiceNewEditDetails from "./InvoiceNewEditDetails";
import InvoiceNewEditAddress from "./InvoiceNewEditAddress";
import InvoiceNewEditStatusDate from "./InvoiceNewEditStatusDate";
import InvoicePaymentDetails from "./InvoicePaymentDetails";
import { setSale, removeSaleIndex } from "../../../redux/slices/global";
import { setBarcodeRes } from "../../../redux/slices/sale";
import { getCurrentBranch } from "../../../utils/jwt";

// ----------------------------------------------------------------------
InvoiceNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentInvoice: PropTypes.object,
  pdf: PropTypes.any,
  Receipt: PropTypes.any,
};

export default function InvoiceNewEditForm({
  isEdit,
  currentInvoice,
  pdf,
  Receipt,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOnline } = useSelector((state) => state.offline);
  const { user, generate, editSale, getBranches, currentBranch, getStaff } =
    useAuth();
  const { shop, settings } = user;
  const allowOutOfStock =
    parseInt(
      settings.filter((setting) => setting.name === "out_of_stock_sales")[0]
        .value,
      10
    ) === 1;
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const { barcodeRes } = useSelector((state) => state.sale);
  // eslint-disable-next-line
  const [_invoiceAddressTo, setinvoiceAddressTo] = useState([]);
  const [_invoiceAddressFrom, setinvoiceAddressFrom] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetch() {
      try {
        const branches = await getBranches(isOnline);
        setinvoiceAddressFrom(branches.filter((obj) => obj.bid !== ""));
      } catch (err) {
        // console.log(err);
      }
    }
    fetch();
    // offline things
    const alertUser = (event) => {
      event.preventDefault();
      event.returnValue =
        "By closing this page, you will not be able to access your store offline. Please note that your sales are still safe and you can access them once you connect your device to the internet.";
    };
    if (!isOnline) {
      window.addEventListener("beforeunload", alertUser);
    }

    return () => {
      setLoadingSave(false);
      setLoadingSend(false);
      setinvoiceAddressFrom([]);
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [getBranches, isOnline]);

  const NewUserSchema = Yup.object().shape({
    date: Yup.string().nullable().required("Date is required"),
  });

  const formik = useFormik({
    initialValues: {
      invoiceFrom: {
        name: shop.name,
        address: `${shop.address}, ${shop.lga}, ${shop.state}.`,
        phone: shop.phone,
        email: shop.email,
        branch: isEdit
          ? {
              bid: currentInvoice.branch.toString(),
              name: currentInvoice.branchName,
            }
          : currentBranch,
      },
      invoiceTo: isEdit
        ? {
            name: currentInvoice.customer.name,
            address: currentInvoice.customer.address || "",
            phone: currentInvoice.customer.phone || "",
            cid: currentInvoice.customer.cid || "",
            email: currentInvoice.customer.email || "",
            credit: currentInvoice.customer.credit || "",
            barcode: currentInvoice.customer.barcode || "",
          }
        : {
            name: "Walk In Customer",
            address: "",
            phone: "",
            cid: "",
            email: "",
            barcode: "",
            credit: "",
          },
      status: isEdit
        ? SALE_STATUS.filter(
            (stat) =>
              parseInt(stat.value, 10) === parseInt(currentInvoice.status, 10)
          )[0].label.toLowerCase()
        : "paid",
      date: isEdit
        ? currentInvoice.date
        : new Date().toISOString().split("T")[0],
      priceChange: isEdit ? currentInvoice.priceChange : "",
      due: isEdit ? currentInvoice.due : "",
      tax: isEdit ? currentInvoice.tax : "",
      discount: isEdit ? currentInvoice.discount : "",
      saleRep: {
        uid: isEdit ? currentInvoice.saleRep : "",
        name: isEdit ? currentInvoice.saleRepName : "",
      },
      tip: isEdit ? currentInvoice.tip : "",
      discountPercent: isEdit ? currentInvoice.discountPercent : "",
      terms: isEdit
        ? String(currentInvoice.terms).split("\\n").join("\n")
        : shop.terms.split("\\n").join("\n"),
      total: isEdit ? currentInvoice.total : "",
      amount: isEdit ? currentInvoice.paid : "",
      change: isEdit ? currentInvoice.balance : "",
      payments:
        isEdit && currentInvoice.payment !== "[]"
          ? JSON.parse(currentInvoice.payment)
          : [
              {
                method: "",
                amount: "",
                ref: "",
                status: false,
                isDebtorAdded: false,
              },
            ],
      products: isEdit
        ? JSON.parse(currentInvoice.products)
        : [
            {
              pid: "",
              name: "",
              warranty: "",
              quantity: "",
              price: "",
              total: "",
              cost: "",
              sell: "",
              editPrice: false,
              qty: 0,
              loading: false,
              open: false,
            },
          ],
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        // check products
        if (values.products.length > 0) {
          const { products, date, due, discount, tax, discountPercent, terms } =
            values;
          const status = loadingSend
            ? (values.status === "draft" && "paid") || values.status
            : "draft";

          const productLeft = products.filter((obj) => obj.pid === "");
          if (productLeft.length === 0) {
            // check quantities
            const qtyLeft = products.filter(
              (obj) => obj.quantity === "" || parseFloat(obj.quantity, 10) <= 0
            );
            if (qtyLeft.length === 0) {
              let total =
                products
                  .map((obj) => Number(obj.price) * Number(obj.quantity))
                  .reduce((partialSum, a) => partialSum + a, 0) +
                (values.tax || 0) +
                (values.tip || 0);
              const isAllDiscounted = total === parseFloat(discount);
              total -= parseFloat(discount) || 0;

              // check total amount
              if (total > 0 || isAllDiscounted) {
                const methodLeft = values.payments.filter(
                  (obj) => obj.method === ""
                );
                const checkPaymentMethod =
                  status === "paid"
                    ? (values.payments.length > 0 && methodLeft.length === 0) ||
                      isAllDiscounted
                    : true;
                if (checkPaymentMethod) {
                  const balance =
                    parseFloat(values.amount) -
                    values.payments
                      .map((obj) => Number(obj.amount))
                      .reduce((partialSum, a) => partialSum + a, 0);
                  const confirmBalance =
                    status === "paid" ? balance === 0 : true;
                  if (confirmBalance) {
                    const amount = parseFloat(values.amount || 0);
                    const checkAmount =
                      status === "paid"
                        ? (amount > 0 && amount >= total) || isAllDiscounted
                        : true;
                    if (checkAmount) {
                      const branch = values.invoiceFrom.branch.bid || "";
                      const customer = values.invoiceTo.cid;
                      const products = JSON.stringify(
                        values.products.filter((obj) => obj.pid !== "")
                      );
                      const payments = JSON.stringify(
                        values.payments.filter((obj) => obj.method !== "")
                      );
                      const change = parseFloat(values.change || 0);

                      let rid = currentInvoice ? currentInvoice.rid : "";
                      if (isEdit && currentInvoice) {
                        await editSale(
                          branch,
                          customer,
                          status,
                          date,
                          due,
                          products,
                          discount,
                          tax,
                          payments,
                          change,
                          amount,
                          total,
                          values.priceChange,
                          discountPercent,
                          values.tip,
                          values.saleRep,
                          terms,
                          currentInvoice.rid
                        );
                      } else {
                        rid = await generate(
                          branch,
                          customer,
                          status,
                          date,
                          due,
                          products,
                          discount,
                          tax,
                          payments,
                          change,
                          amount,
                          total,
                          discountPercent,
                          values.tip,
                          values.saleRep,
                          values.priceChange,
                          terms,
                          isOnline
                        );
                      }
                      if (loadingSend) {
                        dispatch(removeSaleIndex(rid));
                        if (isOnline) {
                          setSubmitting(false);
                          resetForm();
                          navigate(`${PATH_DASHBOARD.subpages.sale}/${rid}`);
                        } else {
                          if (pdf !== null && Receipt !== null) {
                            enqueueSnackbar(
                              `Sale saved successfully. Generating receipt...`,
                              {
                                variant: "success",
                              }
                            );
                            try {
                              const blob = await pdf(
                                // eslint-disable-next-line react/jsx-pascal-case
                                <Receipt.default invoice={rid} user={user} />
                              ).toBlob();
                              const url = URL.createObjectURL(blob);
                              const iframe = document.createElement("iframe");
                              document.body.appendChild(iframe);
                              iframe.style.display = "none";
                              iframe.src = url;
                              iframe.onload = () => {
                                setTimeout(() => {
                                  iframe.focus();
                                  iframe.contentWindow.print();
                                }, 1);
                              };
                            } catch (err) {
                              enqueueSnackbar(
                                `The sale has been saved successfully but receipt cannot be generated at this moment`,
                                {
                                  variant: "warning",
                                }
                              );
                            }
                          } else {
                            enqueueSnackbar(
                              `The sale has been saved successfully but receipt cannot be generated at this moment`,
                              {
                                variant: "warning",
                              }
                            );
                          }
                          setSubmitting(false);
                          resetForm();
                          // scroll to top
                          window.setTimeout(() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }, 500);
                        }
                      } else {
                        // save to draft
                        enqueueSnackbar(`Saved to Pending Sales`, {
                          variant: "success",
                        });
                        values.rid = rid;
                        dispatch(setSale(values));
                        resetForm();
                        // scroll to top
                        window.setTimeout(() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }, 500);
                      }
                      setLoadingSave(false);
                      setLoadingSend(false);
                      window.localStorage.removeItem("sales-form");
                    } else {
                      enqueueSnackbar(
                        `Amount paid is lower than the amount payable!`,
                        { variant: "error" }
                      );
                    }
                  } else {
                    enqueueSnackbar(`Please confirm all payment to proceed!`, {
                      variant: "error",
                    });
                  }
                } else {
                  enqueueSnackbar(`No payment method added!`, {
                    variant: "error",
                  });
                }
              } else if (total === 0) {
                enqueueSnackbar(`No product added! `, { variant: "error" });
              } else {
                enqueueSnackbar(
                  `Your discount is greater than the total amount payable.`,
                  { variant: "error" }
                );
              }
            } else {
              enqueueSnackbar(
                `One or more quantities are not specified! Please confirm and try again.`,
                { variant: "error" }
              );
            }
          } else {
            enqueueSnackbar(`No product selected!`, { variant: "error" });
          }
        } else {
          enqueueSnackbar(`No product selected!`, { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
        setSubmitting(false);
      }
    },
  });

  const { isSubmitting, handleSubmit, values, setFieldValue } = formik;

  const payable =
    parseFloat(
      values.products
        .map((obj) => Number(obj.price) * Number(obj.quantity))
        .reduce((partialSum, a) => partialSum + a, 0) || 0
    ) -
    (parseFloat(values.discount) || 0) +
    (parseFloat(values.tax) || 0) +
    (parseFloat(values.tip) || 0);

  useEffect(() => {
    async function fetch() {
      try {
        setStaffs(
          await getStaff(
            "",
            values.invoiceFrom.branch.bid || getCurrentBranch(),
            isOnline
          )
        );
      } catch (err) {
        //  console.log(err);
      }
    }
    fetch();
    return () => {
      setStaffs([]);
    };
  }, [getStaff, values.invoiceFrom, isOnline]);

  const checkBarcode = useCallback(() => {
    // process new res;
    if (Object.prototype.hasOwnProperty.call(barcodeRes, "type")) {
      const barcodeType = barcodeRes.type;
      const bodyData = barcodeRes.result;
      if (barcodeType === "loyalty_card") {
        if (bodyData.customerName) {
          // set customer
          setFieldValue("invoiceTo", {
            name: bodyData.customerName,
            email: bodyData.customerEmail,
            phone: bodyData.customerPhone,
            address: bodyData.customerAddress,
            dob: bodyData.customerDOB,
            gender: "",
            credit: bodyData.amount,
            cid: bodyData.customer,
            barcode: bodyData.barcode,
          });
        } else {
          enqueueSnackbar(
            `This loyalty card is not attached to any customer and cannot be used!`,
            { variant: "error" }
          );
        }
      } else if (barcodeType === "product") {
        // set product
        const { pid } = bodyData;
        const { products } = values;
        const check = products.filter((product) => product.pid === pid);
        if (check.length === 0) {
          if (products.length === 1 && products[0].pid === "") {
            products[0] = {
              pid: bodyData.pid,
              name: bodyData.name,
              quantity:
                parseInt(bodyData.qty, 10) < 1 && !allowOutOfStock ? "0" : "1",
              warranty: "",
              qty: bodyData.qty,
              price: bodyData.sellingPrice,
              cost: bodyData.costPrice,
              sell: bodyData.sellingPrice,
              editPrice: false,
              loading: false,
              open: false,
            };
          } else {
            // add a new one
            products.push({
              pid: bodyData.pid,
              name: bodyData.name,
              quantity:
                parseInt(bodyData.qty, 10) < 1 && !allowOutOfStock ? "0" : "1",
              warranty: "",
              qty: bodyData.qty,
              price: bodyData.sellingPrice,
              cost: bodyData.costPrice,
              sell: bodyData.sellingPrice,
              editPrice: false,
              loading: false,
              open: false,
            });
          }
          if (parseInt(bodyData.qty, 10) <= 0) {
            enqueueSnackbar(
              `${bodyData.name}'s stock is empty! Please top up.`,
              {
                variant: "warning",
              }
            );
          }
          setFieldValue(`products`, products);
        } else {
          // update qty of existing;
          const newProducts = products.map((product) => {
            if (product.pid === pid) {
              const newPrd = product;
              const qty = parseInt(newPrd.quantity, 10) + 1;
              const total = qty * parseFloat(newPrd.price);
              newPrd.quantity = qty;
              newPrd.total = total;
              if (qty > parseInt(bodyData.qty, 10)) {
                const productName = newPrd.name;
                enqueueSnackbar(
                  `${productName}'s quantity (${bodyData.qty}) is too low for the requested quantity.`,
                  { variant: "warning" }
                );
                if (!allowOutOfStock) {
                  const qty = parseInt(bodyData.qty, 10);
                  const total = qty * parseFloat(newPrd.price);
                  newPrd.quantity = qty;
                  newPrd.total = total;
                }
              }
              return newPrd;
            }
            return product;
          });
          setFieldValue(`products`, newProducts);
        }
      } else if (barcodeType === "gift_card") {
        // ignore
      } else {
        enqueueSnackbar(
          `Please scan only loyalty card or products to proceed.`,
          { variant: "error" }
        );
      }
      dispatch(setBarcodeRes({}));
    }
  }, [
    barcodeRes,
    enqueueSnackbar,
    setFieldValue,
    values,
    dispatch,
    allowOutOfStock,
  ]);

  useEffect(() => {
    checkBarcode();
  }, [checkBarcode]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card
          variant="outlined"
          sx={{
            border: "0.4px solid",
            borderColor: "background.border",
            boxShadow:
              "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
            borderRadius: "8px",
          }}
        >
          <InvoiceNewEditAddress
            formik={formik}
            _invoiceAddressFrom={_invoiceAddressFrom}
            _invoiceAddressTo={_invoiceAddressTo}
            isOnline={isOnline}
          />
          <InvoiceNewEditStatusDate formik={formik} staffs={staffs} />
          <InvoiceNewEditDetails
            isEdit={isEdit}
            formik={formik}
            payable={payable}
            branch={values.invoiceFrom.branch}
            isOnline={isOnline}
          />
          <InvoicePaymentDetails formik={formik} />
        </Card>

        <Stack
          justifyContent={{ md: "flex-end", xs: "center" }}
          alignItems="center"
          direction={{ md: "row", xs: "column-reverse" }}
          spacing={2}
          sx={{ mt: 3 }}
        >
          {isOnline && (
            <LoadingButton
              color="secondary"
              size="large"
              variant="contained"
              loading={loadingSave && isSubmitting}
              onClick={() => {
                setLoadingSend(false);
                setLoadingSave(true);
                handleSubmit();
              }}
              sx={{ width: { md: "auto", xs: 1 } }}
            >
              Save as Draft
            </LoadingButton>
          )}
          <LoadingButton
            size="large"
            variant="contained"
            loading={loadingSend && isSubmitting}
            onClick={() => {
              setLoadingSave(false);
              setLoadingSend(true);
              handleSubmit();
            }}
            sx={{ width: { md: "auto", xs: 1 } }}
          >
            {isEdit ? "Update" : (isOnline && "Create") || "Save & Print"}
          </LoadingButton>
        </Stack>
        {!isEdit && <Persist name="sales-form" />}
      </Form>
    </FormikProvider>
  );
}
