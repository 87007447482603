// material
import PropTypes from "prop-types";
import { Card, CardHeader, Typography, Stack, Link } from "@mui/material";
// utils
import Scrollbar from "../../Scrollbar";
import Image from "../../Image";
import { ASSETS_PATH } from "../../../utils/apis";

// ----------------------------------------------------------------------
LatestPosts.propTypes = {
  posts: PropTypes.array,
};
export default function LatestPosts({ posts }) {
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0px",
        boxShadow: "none",
        bgcolor: "background.default",
        pb: 3,
        px: 0,
      }}
    >
      <CardHeader title="Check out our latest posts" sx={{ mb: 3, px: 0 }} />
      <Scrollbar>
        <Stack direction="row" spacing={2} sx={{ width: "max-content" }}>
          {posts.map((post, index) => (
            <Link
              key={index}
              href={post.slug}
              target="_blank"
              sx={{ color: "unset" }}
              underline="none"
            >
              <Stack
                key={index}
                sx={{ width: 250, borderRadius: 1 }}
                spacing={0.5}
              >
                <Image
                  src={`${ASSETS_PATH.posts}${post.photo}`}
                  width={250}
                  height={150}
                  sx={{
                    borderRadius: 1,
                    "& img": { borderRadius: 1, objectFit: "cover" },
                  }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    width: 1,
                    WebkitLineClamp: 2,
                    lineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {post.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    overflow: "hidden",
                    display: "-webkit-box",
                    width: 1,
                    WebkitLineClamp: 3,
                    lineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {post.tagline}
                </Typography>
              </Stack>
            </Link>
          ))}
        </Stack>
      </Scrollbar>
    </Card>
  );
}
